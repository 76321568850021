import React, { useEffect } from "react";
import styled from "styled-components";
import { useQueryParam, StringParam } from "use-query-params";

import CodeDingLogo from "../../images/CodeDingLogoFooter.png";

const eventMap = {
  "user-connected": {
    title: "Success!",
    description: `You've linked your Gitlab account with your Slack user. You will now
    receive notifications in Slack when a merge request requires your
    attention.`,
  },
  "app-install": {
    title: "Success!",
    description: "CodeDing has been installed on your Slack workspace.",
  },
  "app-install-failed": {
    title: "Oops.",
    description:
      "It looks like there was a problem installing the app. Please try again.",
  },
};

const AppEvent = () => {
  const [event] = useQueryParam("v", StringParam);
  const [teamId] = useQueryParam("t", StringParam);
  const [appId] = useQueryParam("a", StringParam);

  const { title = "Oops.", description = "We are not sure how you got here." } =
    eventMap[event] || {};

  useEffect(() => {
    if (teamId && appId) {
      window.open(`slack://app?team=${teamId}&id=${appId}`);
    }
  }, [event, teamId, appId]);

  return (
    <EventWrapper>
      <EventLogo src={CodeDingLogo} alt="Logo" />
      <EventDescription>
        <EventBold>{title}</EventBold>
        {description}
      </EventDescription>
    </EventWrapper>
  );
};

export default AppEvent;

const EventWrapper = styled.div`
  text-align: center;
  padding: 15vh 8rem 6rem 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EventLogo = styled.img`
  height: 6rem;
  width auto;
`;

const EventBold = styled.h1`
  font-weight: bold;
  padding-right: 8px;
  margin: 0;
  font-size: 1.4rem;
`;

const EventDescription = styled.p`
  display: block;
  max-width: 420px;
  min-width: 270px;
  flex-grow: 4;
`;
