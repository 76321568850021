import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import UserConnected from "../components/events/event";

const UserConnectedPage = () => (
  <Layout>
    <SEO title="CodeDing - Success!" />
    <UserConnected />
  </Layout>
);

export default UserConnectedPage;
